<template>
  <div>
    <PageHeader />
    <b-overlay :show="isLoading">
      <b-card class="mb-4">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, failed }">
          <b-toast :visible="failed" title="Warning" variant="warning">
            {{ $t('messages.invalid_form') }}
          </b-toast>
          <form autocomplete="off" @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              v-if="!isAgent"
              v-slot="{ errors }"
              name="createFor"
              rules="required"
            >
              <b-form-group
                label="สร้างประกาศสำหรับ"
                label-cols-lg="2"
                label-cols-sm="12"
              >
                <b-select
                  id="createFor"
                  v-model="createFor"
                  :state="errors[0] ? false : null"
                  required
                >
                  <b-form-select-option :value="null"
                    >-- สร้างประกาศสำหรับ --</b-form-select-option
                  >
                  <b-form-select-option v-if="isOwner" value="OWNER"
                    >ทุกเอเย่นต์</b-form-select-option
                  >
                  <b-form-select-option
                    v-if="isOwner || isMaster"
                    value="MASTER"
                    >มาสเตอร์</b-form-select-option
                  >
                  <b-form-select-option v-if="isOwner || isMaster" value="AGENT"
                    >เอเย่นต์</b-form-select-option
                  >
                </b-select>
              </b-form-group>
            </ValidationProvider>

            <b-form-group
              v-if="isOwner && ['MASTER', 'AGENT'].includes(createFor)"
              label="เลือก Master"
              label-cols-lg="2"
              label-cols-sm="12"
            >
              <MasterSelectInput
                :value="form.masterId"
                hide-label
                @update="(masterId) => (form.masterId = masterId)"
              />
            </b-form-group>

            <b-form-group
              v-if="['AGENT'].includes(createFor)"
              label="เลือก Agent"
              label-cols-lg="2"
              label-cols-sm="12"
            >
              <AgentsSelectInput
                :master-id="form.masterId"
                :value="form.agentId"
                hide-label
                @update="(agentId) => (form.agentId = agentId)"
              />
            </b-form-group>

            <ValidationProvider
              v-slot="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-group
                label="หัวข้อประกาศ"
                label-cols-lg="2"
                label-cols-sm="12"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="content"
              rules="required"
            >
              <b-form-group
                label="คอนเทนต์"
                label-cols-lg="2"
                label-cols-sm="12"
              >
                <vue-editor
                  id="content"
                  v-model="form.content"
                  :state="errors[0] ? false : null"
                  use-custom-image-handler
                  @image-added="handleImageAdded"
                  @image-removed="handleImageRemoved"
                >
                </vue-editor>
              </b-form-group>
            </ValidationProvider>
            <b-form-group
              label="ตั้งค่าปุ่ม"
              label-cols-lg="2"
              label-cols-sm="12"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox 
                    ref="isCustomButton"
                    v-model="form.isCustomButton"
                    name="isCustomButton"
                    switch
                  />
                </b-input-group-prepend>
                <b-input-group-prepend is-text> 
                  <b>ข้อความ</b>
                </b-input-group-prepend>
                <b-form-input
                  id="buttonName"
                  v-model="form.buttonName"
                  :aria-invalid="false"
                  :disabled="!form.isCustomButton"
                  :state="form.isCustomButton === true && !form.buttonName ? false : null"
                  placeholder="คลิก"
                  type="text"
                >
                </b-form-input>
                <b-input-group-prepend is-text> 
                  <b>ลิงค์ปลายทาง</b>
                </b-input-group-prepend>
                <b-form-input
                 id="buttonTargetUrl"
                 v-model="form.buttonTargetUrl"
                 :aria-invalid="false"
                 :disabled="!form.isCustomButton"
                 :state="form.isCustomButton === true && !form.buttonTargetUrl ? false : null"
                 placeholder="https://example.com"
                 type="url"
                 >
                </b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group
                label="เวลาเปิด"
                label-cols-lg="2"
                label-cols-sm="12"
            >
              <DateTimePicker
                v-model="form.startDateTime"
                :max-date="haveEndDateTime ? form.endDateTime : '-'"
              />
            </b-form-group>

            <b-form-group label="เวลาปิด" label-cols-lg="2" label-cols-sm="12">
              <b-form-checkbox
                v-model="haveEndDateTime"
                name="haveEndDateTime"
                switch
              >
              </b-form-checkbox>

              <DateTimePicker
                v-if="haveEndDateTime"
                v-model="form.endDateTime"
                :min-date="form.startDateTime"
                class="mt-3"
              />
            </b-form-group>

            <b-form-group label-cols-lg="2" label-cols-sm="12">
              <b-form-checkbox v-model="form.isClose" name="isClose" switch>
                ซ่อนประกาศ
              </b-form-checkbox>
            </b-form-group>

            <div class="text-right">
              <b-button class="mr-2" to="/announce/list" variant="light">
                {{ $t('buttons.back') }}
              </b-button>
              <b-overlay
                :show="isLoading"
                class="d-inline-block"
                rounded
                spinner-small
              >
                <b-button block type="submit" variant="primary">
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor'
import {mapActions, mapGetters} from 'vuex'
import dayjs from 'dayjs'

export default {
  page: {
    title: 'ประกาศ',
  },
  components: {
    VueEditor,
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      createFor: null,
      isLoading: false,
      haveEndDateTime: false,
      toDay: dayjs().format('YYYY-MM-DD HH:mm'),
      form: {
        agentId: null,
        masterId: null,
        title: null,
        content: null,
        isClose: false,
        startDateTime: null,
        endDateTime: null,
        buttonName: null,
        buttonTargetUrl: null,
        isCustomButton: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'isOwner',
      'isMaster',
      'announceDetail',
      'userInfo',
    ]),
    announcementId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  async created() {
    if (this.announcementId) {
      this.isLoading = true
      await this.fetchAnnounce(this.announcementId)
      await this.setData()
      this.isLoading = false
    } else {
      this.form.startDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
    }
  },
  methods: {
    ...mapActions([
      'createAnnounce',
      'updateAnnounce',
      'fetchAnnounce',
      'uploadFile',
      'removeFile',
    ]),
    async setData() {
      this.form = {
        ...this.form,
        ...this.announceDetail,
        startDateTime: dayjs(this.announceDetail.startDateTime).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        endDateTime: this.announceDetail.endDateTime
          ? dayjs(this.announceDetail.endDateTime).format('YYYY-MM-DD HH:mm:ss')
          : null,
      }
      this.haveEndDateTime = !!this.form.endDateTime
      this.form.isCustomButton = !!this.form.buttonName && !!this.form.buttonTargetUrl
      //
      if (this.announceDetail.agent === null) {
        this.form.agentId = null
        this.createFor = 'OWNER'
      } else {
        let master = this.userInfo.masters.find(
          (master) => master.id === this.announceDetail.agent.id
        )
        if (master) {
          this.form.agentId = null
          this.form.masterId = master.id
          this.createFor = 'MASTER'
        } else {
          this.userInfo.masters.forEach((master) => {
            const agent = master.children.find(
              (agent) => agent.id === this.announceDetail.agent.id
            )
            if (agent) {
              this.form.agentId = agent.id
              this.form.masterId = master.id
            }
          })
          this.createFor = 'AGENT'
        }
      }
    },
    onSubmit() {
      if (this.isAgent) {
        this.form.agentId = this.userInfo.agentId
      } else if (this.createFor === 'OWNER') {
        this.form.agentId = null
      } else if (this.createFor === 'MASTER') {
        if (this.isMaster) {
          this.form.agentId = this.userInfo.agentId
        } else {
          this.form.agentId = this.form.masterId
        }
      }

      if (!this.haveEndDateTime) {
        this.form.endDateTime = null
      }
      if (this.form.isCustomButton == false) {
        this.form.buttonName = null
        this.form.buttonTargetUrl = null
      }

      if (this.announcementId) {
        this.updateAnnounce({
          id: this.announcementId,
          data: this.form,
        })
      } else {
        this.createAnnounce(this.form)
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      await this.uploadFile(file)
        .then((result) => {
          Editor.insertEmbed(cursorLocation, 'image', result)
          resetUploader()
        })
        .catch(() => {
        })
    },
    async handleImageRemoved(file) {
      await this.removeFile(file).catch(() => {
      })
    },
  },
}
</script>